import {Control, Controller} from 'react-hook-form'
import {Box, FormControl, Grid, Rating, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type props = {
    control: Control<any>
    name: string
    size?: "large" | "medium" | "small" | undefined
    defaultValue?: number
    precision?: number
    label: string
    disabled?: boolean
}


const labels: { [index: string]: string } = {
    0: '',
    1: 'poor',
    2: 'fair',
    3: 'good',
    4: 'very_good',
    5: 'outstanding',
};


export const RatingController = ({
                                     control,
                                     name,
                                     label,
                                     precision,
                                     disabled = false,
                                     size = 'medium'
                                 }: props) => {
    const {t} = useTranslation();
    const [hover, setHover] = React.useState(5);
    return <Grid container spacing={0}
                 direction="row"
                 alignItems="center"
                 justifyContent="flex-start">
        <Grid item xs={12} sm={6}  sx={{
            // specify multiple values applied in specific breakpoints
            paddingTop: {
                xs: "15px",
                sm: "10px",
            },
        }}>
            <Typography>{t(label)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Controller
                control={control}
                render={({field, fieldState: {error}}) => (
                    <FormControl fullWidth={true} margin="dense">
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: {
                            xs:"center",
                                sm:"normal"
                            }}}>
                            <Rating
                                {...field}
                                value={field.value  }
                                readOnly={disabled}
                                precision={precision}
                                size={size}
                                onChange={(val) => field.onChange(val)}
                                onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                }}

                            />
                            {field.value !== null && !error && (
                                <Box sx={{  ml: 2}}>{t(labels[hover !== -1 ? hover : field.value])}</Box>
                            )}
                            {error && (
                                <Box sx={{ml: 2, color: 'red'}}>{t(error?.message || '')}</Box>
                            )}
                        </Box>
                    </FormControl>
                )}
                name={name}
            />
        </Grid>
    </Grid>
}