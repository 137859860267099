import React, {useMemo, useState} from "react"
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Box, Button, Grid, Typography} from "@mui/material"
import {useCheckFeedbackUnauthorized} from "../hooks/useCheckFeedbackUnauthorized";
import {SuccessErrorBox} from "./SuccessErrorBox";
import {useFeedbackUnauthorized} from "../hooks/useFeedbackUnauthorized";
import {feedbackSchema} from "../config/YupSchemaValidations";
import i18n from "i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {RatingController} from "../components/form/RatingController";
import {TextFieldController} from "../components/form/TextFieldController";
import {useBookingUnauthorized} from "../hooks/bookings/useBookingUnauthorized";
import {ISO_DATE_FORMAT} from "../config/const";
import moment from "moment";

export const Feedback = () => {
    const [submited, setSubmited] = useState(false);
    const [searchParams] = useSearchParams();
    const {
        UseCheckFeedback,
        isLoading
    } = useCheckFeedbackUnauthorized(Number(searchParams?.get("bookingId")), searchParams?.get("token"))
    const {UseFeedback} = useFeedbackUnauthorized(Number(searchParams?.get("bookingId")))
    const {t} = useTranslation()
    const schema = useMemo(() => feedbackSchema(), [i18n.language]);
    const {handleSubmit, control, getValues} = useForm(
        {
            defaultValues: {foodAndDrinksRating: 5, atmosphereAndServiceRating: 5, skiperRating: 5},
            resolver: yupResolver(schema)
        })
    const {booking} = useBookingUnauthorized(Number(searchParams?.get("bookingId")), searchParams?.get("token") as string)

    const submitFeedback = (values: any) => {
        const payload = {
            token: searchParams.get("token") as string,
            ...values
        };
        UseFeedback(payload);
        setSubmited(true);
    };
    if (!isLoading && submited) {
        return <SuccessErrorBox isSuccess={true} message={t('feedback_sent_successfully')}/>
    }
    if (!isLoading && UseCheckFeedback?.feedbackAlreadyGiven) {
        return <SuccessErrorBox isSuccess={false} message={t('feedback_already_sent')}/>
    }
    return (
        <Grid container spacing={0}

              direction="column"
              alignItems="center"
              justifyContent="center"
              minHeight="100vh"
              sx={{
                  backgroundColor: "#fbfafd", paddingTop: {
                      xs: "100px",
                      sm: "10px",
                  },
                  paddingBottom: {
                      xs: "60px",
                      sm: "10px",
                  },
                  paddingLeft: {
                      xs: "15px",
                  },
                  paddingRight: {
                      xs: "15px",
                  }
              }}>
            {!isLoading && (<>
                <Typography variant={'h5'}
                            sx={{fontWeight: 'normal'}}
                            align={'center'}>{booking?.guestName}{t('feedback_question')} {booking?.serviceProviderName}?</Typography>
                <Typography variant={'h6'} sx={{mt: 2, display: {xs: "none", sm:"block"}}}>
                    {`${t('reservation_date')}: ${moment(booking?.bookingTime, ISO_DATE_FORMAT).locale("en").format('D')} 
                    ${t(moment(booking?.bookingTime, ISO_DATE_FORMAT).locale("en").format('MMMM').toLowerCase())} ${t(moment(booking?.bookingTime, ISO_DATE_FORMAT).locale("en").format('YYYY'))}`}
                </Typography>
                <Typography variant={'h6'} sx={{mt: 2, display: {xs: "block", sm:"none"}}}>
                    {`${t('reservation_date')}`}
                    <br/>
                    {`${moment(booking?.bookingTime, ISO_DATE_FORMAT).locale("en").format('D')} 
                    ${t(moment(booking?.bookingTime, ISO_DATE_FORMAT).locale("en").format('MMMM').toLowerCase())} ${t(moment(booking?.bookingTime, ISO_DATE_FORMAT).locale("en").format('YYYY'))}`}
                </Typography>
                <Box sx={{width: {
                        xs:"100%",
                        sm:"50%"
                    },
                    textAlign:{
                        xs:"center",
                        sm:"left"
                    }
                    }} component="form" onSubmit={handleSubmit(submitFeedback)}>
                    <RatingController control={control} size={"large"} name={'foodAndDrinksRating'} precision={1}
                                      label={'food_and_drinks'}/>
                    <RatingController control={control} size={"large"} name={'atmosphereAndServiceRating'} precision={1}
                                      label={'atmosphere_and_service'}/>
                    <RatingController control={control} size={"large"} name={'skiperRating'} precision={1}
                                      label={'customer_experience'}/>
                    <TextFieldController control={control} name={'comment'} label={'comment'} multiline={true}/>
                    <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
                </Box>
            </>)}
        </Grid>
    )
}