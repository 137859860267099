import {Control, Controller} from 'react-hook-form'
import {Autocomplete, FormControl, TextField} from '@mui/material'
import React from 'react'
import {OptionsType} from 'types/General'
import {useTranslation} from 'react-i18next'
import {PLUGIN_ICON_COLOR} from 'theme/Plugin.styles'

type props = {
    control: Control<any>
    name: string
    label: string
    disabled?: boolean
    defaultValue?: string | number | number[]
    options: OptionsType[]
    plugin?: boolean
    size?: 'small' | 'medium'
    multiple?: boolean
}


export const AutocompleteController = ({control, name, label, disabled, plugin, defaultValue, multiple = false, options, size = 'medium'}: props) => {
    const {t} = useTranslation()

    return <Controller
        control={control}
        render={({field: {onChange, value}, fieldState: {error}}) => {
            return (
                <FormControl fullWidth={true} margin={'dense'}>
                    <Autocomplete
                        id={'subType'}
                        options={options}
                        limitTags={3}
                        getOptionLabel={(type) => {
                            if (plugin) {
                                return type.label
                            } else {
                                return t(type?.label?.toLowerCase().replace(/ /g, "_"))
                            }
                        }}
                        multiple={multiple}
                        disableClearable={!multiple}
                        renderInput={(params =>
                            <TextField
                                {...params}
                                variant={'outlined'}
                                label={t(label)}
                                value={value || ''}
                                size={size}
                                InputLabelProps={plugin ? {sx: {color: PLUGIN_ICON_COLOR}} : {}}
                                autoComplete='off'
                                error={!!error}
                                helperText={error?.message}
                            />)}
                        onChange={(_, data) => onChange(data instanceof Array ? data : (data ? data.value : null))}
                        value={typeof value === 'string' || typeof value === 'number' ? options.find(o => o.value === value) : (value)}
                        filterOptions={(options, state) => options.filter(option => {
                                if (multiple) {
                                    return !value.some((val: any) => val.value === option.value)
                                        && t(option.label.toLowerCase()).toLowerCase().includes(state.inputValue.toLowerCase())
                                } else {
                                    return t(option.label.toLowerCase()).toLowerCase().includes(state.inputValue.toLowerCase())
                                }
                            }
                        )
                        }
                        disabled={disabled}
                    />
                </FormControl>
            )
        }
        }
        defaultValue={defaultValue}
        name={name}/>

}