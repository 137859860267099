import * as yup from 'yup';
import i18n from "i18next";

export const highlightDishSchema = () =>
    yup.object().shape({
        highlightDishes: yup.object().shape({
            EN: yup.array().of(yup.object().shape({
                    title: yup.string().when('description', {
                        is: (description: string) => description,
                        then: yup.string().required(i18n.t('title_required')).nullable(),
                        otherwise: yup.string().nullable(),
                    }),
                    description: yup.string().when('title', {
                        is: (title: string) => title,
                        then: yup.string().required(i18n.t('description_required')).nullable(),
                        otherwise: yup.string().nullable(),
                    }),
                    languageCode: yup.string()
                }, [['title', 'description']])
            ),
            DE: yup.array().of(yup.object().shape({
                    title: yup.string().when('description', {
                        is: (description: string) => description,
                        then: yup.string().required(i18n.t('title_required')).nullable(),
                        otherwise: yup.string().nullable(),
                    }),
                    description: yup.string().when('title', {
                        is: (title: string) => title,
                        then: yup.string().required(i18n.t('description_required')).nullable(),
                        otherwise: yup.string().nullable(),
                    }),
                    languageCode: yup.string()
                }, [['title', 'description']])
            ),
            KA: yup.array().of(yup.object().shape({
                    title: yup.string().when('description', {
                        is: (description: string) => description,
                        then: yup.string().required(i18n.t('title_required')).nullable(),
                        otherwise: yup.string().nullable(),
                    }),
                    description: yup.string().when('title', {
                        is: (title: string) => title,
                        then: yup.string().required(i18n.t('description_required')).nullable(),
                        otherwise: yup.string().nullable(),
                    }),
                    languageCode: yup.string()
                }, [['title', 'description']])
            )
        })
    });