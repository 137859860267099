import React, {useEffect, useState} from 'react';
import {Button, Grid} from '@mui/material'
import {useForm} from 'react-hook-form'
import {useUpdateHighlightDishes} from 'hooks/serviceProvider/useUpdateHighlightDishes'
import HighlightDishInput from 'components/HighlightDishInput'
import {useHighlightDishes} from 'hooks/serviceProvider/useHighlightDishes'
import {useTranslation} from 'react-i18next'
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {highlightDishSchema} from "../highlightDishSchema";
import {useMember} from "../../hooks/members/useMember";
import {useServiceProvider} from "../../hooks/useServiceProvider";


const HighlightDishes = () => {
    const {t} = useTranslation()
    const {serviceProvider} = useServiceProvider()
    const {highlightDishes, highlightDishesArray} = useHighlightDishes()
    const {handleSubmit, control, reset} = useForm({defaultValues: highlightDishesArray, resolver: yupResolver(highlightDishSchema())})
    const {UpdateHighlightDishes} = useUpdateHighlightDishes()
    const {member} = useMember()
    const onSubmit = (values: any) => {
        const en = values?.highlightDishes?.EN.filter((highlightDish: any) => highlightDish.title && highlightDish.description)
        const de = values?.highlightDishes?.DE.filter((highlightDish: any) => highlightDish.title && highlightDish.description)
        const ka = values?.highlightDishes?.KA.filter((highlightDish: any) => highlightDish.title && highlightDish.description)
        let filterEmptyValues = {
            highlightDishes: [...en, ...de]
        }
        if (member?.languageCode == 'KA') {
            filterEmptyValues = {
                highlightDishes: [...en, ...ka]
            }
        }
        UpdateHighlightDishes(filterEmptyValues)
    }

    useEffect(() => {
        if (highlightDishes) {
            reset(highlightDishesArray)
        }
    }, [highlightDishes])

    const [highlightDishLanguage, setHighlightDishLanguage] = useState<string>()

    useEffect(() => {
        if (serviceProvider?.address?.country == 'Georgia') {
                setHighlightDishLanguage("KA")
        } else if (serviceProvider?.address?.country == 'Germany') {
            setHighlightDishLanguage("DE")
        }
    }, [serviceProvider]);

    return (
        <>
            {highlightDishes &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <HighlightDishInput control={control} dishNumber={0} headerNumber={1} language={highlightDishLanguage}/>
                        <HighlightDishInput control={control} dishNumber={1} headerNumber={2} language={highlightDishLanguage}/>
                        <HighlightDishInput control={control} dishNumber={2} headerNumber={3} language={highlightDishLanguage}/>
                        <HighlightDishInput control={control} dishNumber={3} headerNumber={4} language={highlightDishLanguage}/>
                    </Grid>
                    <Grid container spacing={1}>
                        <HighlightDishInput control={control} dishNumber={0} headerNumber={1} language={'EN'}/>
                        <HighlightDishInput control={control} dishNumber={1} headerNumber={2} language={'EN'}/>
                        <HighlightDishInput control={control} dishNumber={2} headerNumber={3} language={'EN'}/>
                        <HighlightDishInput control={control} dishNumber={3} headerNumber={4} language={'EN'}/>
                    </Grid>
                    <Button variant={'contained'} fullWidth type={'submit'}>{t('save')}</Button>
                </form>}
        </>
    );
};

export default HighlightDishes;
